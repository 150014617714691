import React, { useEffect, useState } from "react";
import { FaMobileRetro } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import TextView from "./TextView";
import { IoSend } from "react-icons/io5";
import { AiOutlineLoading } from "react-icons/ai";
import styles from "./../App.module.css";
import validator from "validator";
const getDirectionByString = (text) => {
  return isFirstCharEnglish(text) ? "ltr" : "rtl";
};
function isFirstCharEnglish(str) {
  // Check if the string is not empty
  if (str && str.length > 0) {
    // Use a regular expression to check if the first character is an English letter
    const regex = /^[a-zA-Z]/;
    return regex.test(str);
  }

  return false;
}
const THEME_COLOR = "#2871e7";
function EmailForm({ askForEmail, createConversation }) {
  /**
   * email / phone
   */
  const [selectedType, setSelectedType] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isRequsting, setIsRequesting] = useState(false);
  let placeholder = "";

  if (selectedType == "phone") {
    placeholder = "لطفا شماره موبایل خود را وارد کنید...";
  } else if (selectedType == "email") {
    placeholder = "لطفا ایمیل خود را وارد کنید...";
  }
  const sendData = async () => {
    setIsRequesting(true);

    let value = "";
    if (selectedType == "phone") {
      value = convertPhone(phone);
    } else {
      value = email;
    }
    createConversation({
      message: askForEmail.message[0],
      contact_info: {
        type: selectedType,
        value: value,
      },
    }).then(() => {
      setIsRequesting(false);
    });
  };
  const [currentTypeIsValid, setCurrentTypeIsValid] = useState(false);

  function convertToEnglishNumber(string) {
    if (!string) return "";
    let farsiNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
    farsiNumbers.forEach((number, index) => {
      string = string.replace(new RegExp(number, "g"), String(index));
    });

    return string;
  }
  const convertPhone = (input) => {
    return (
      "989" +
      [...convertToEnglishNumber(input)]
        .reverse()
        .slice(0, 9)
        .reverse()
        .join("")
    );
  };

  const mobileIsValid = (input) => {
    const regex = /^(\+98|0|98|0098)?9\d{9}$/;
    return regex.test(String(input));
  };
  useEffect(() => {
    let result = false;
    if (selectedType == "phone") {
      if (
        mobileIsValid(convertPhone(phone)) &&
        convertPhone(phone).length > 9
      ) {
        result = true;
      }
    }
    if (selectedType == "email") {
      result = validator.isEmail(email);
    }
    setCurrentTypeIsValid(result);
  }, [phone, email, selectedType]);
  return (
    <React.Fragment>
      <div className={`${styles.message} ${styles["text-only"]}`}>
        <div className={styles.response}>
          <p
            style={{
              direction: getDirectionByString(askForEmail.message[0]),
            }}
            className={styles.text}
          >
            <TextView text={askForEmail.message[0]} />
          </p>
        </div>
      </div>
      <div className={`${styles.message} ${styles["text-only"]}`}>
        <div className={styles.response}>
          <div
            style={{
              direction: "rtl",
            }}
            className={`${styles["text"]} ${styles["blue-bubble"]}`}
          >
            <div style={{ fontSize: 14, fontStyle: "unset" }}>
              لطفا ایمیل یا شماره همراه خود را برای ادامه گفتگو وارد کنید
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              padding: 10,
              paddingTop: 0,
            }}
          >
            <div
              onClick={() => {
                setSelectedType("email");
              }}
              className={styles["phone-email-btn"]}
              style={{
                backgroundColor:
                  selectedType == "email" ? THEME_COLOR : "inherit",
                color: selectedType == "email" ? "white" : THEME_COLOR,
              }}
            >
              ایمیل
              <IoIosMail
                style={{
                  alignSelf: "center",

                  boxSizing: "content-box",
                }}
              />
            </div>
            <div
              style={{
                backgroundColor:
                  selectedType == "phone" ? "#2871e7" : "inherit",
                color: selectedType == "phone" ? "white" : "#2871e7",
              }}
              onClick={() => {
                setSelectedType("phone");
              }}
              className={styles["phone-email-btn"]}
            >
              تلفن همراه
              <FaMobileRetro
                style={{ alignSelf: "center", boxSizing: "content-box" }}
              />
            </div>
          </div>
          {selectedType && (
            <div
              style={{
                display: "flex",
                //   justifyContent: "end",
                //   background: "red",
                padding: 10,
              }}
            >
              <div
                onClick={() => {
                  if (currentTypeIsValid) {
                    sendData();
                  }
                }}
                style={{
                  display: "flex",
                  padding: 10,
                  cursor: "pointer",
                  opacity: currentTypeIsValid ? 1 : 0.5,
                }}
              >
                {isRequsting == false && (
                  <IoSend
                    style={{
                      alignSelf: "center",
                      transform: "scale(-1)",
                      color: THEME_COLOR,
                      boxSizing: "content-box",
                      fontSize: "1.2rem",
                    }}
                  />
                )}

                {isRequsting && (
                  <AiOutlineLoading
                    className="image-loading"
                    style={{
                      boxSizing: "content-box",
                      alignSelf: "center",
                    }}
                  ></AiOutlineLoading>
                )}
              </div>

              {selectedType == "phone" && (
                <input
                  readOnly={isRequsting}
                  placeholder={placeholder}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      sendData();
                    }
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: 10,
                    direction: phone.length == 0 ? "rtl" : "ltr",
                    borderColor: "#2b71e7",
                    borderRadius: 7,
                  }}
                ></input>
              )}

              {selectedType == "email" && (
                <input
                  disabled={isRequsting}
                  placeholder={placeholder}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      sendData();
                    }
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    padding: 10,
                    direction: email.length == 0 ? "rtl" : "ltr",
                    borderColor: "#2b71e7",
                    borderRadius: 7,
                    opacity: isRequsting ? 0.5 : 1,
                  }}
                ></input>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default EmailForm;
