import React, { useState, useRef, useEffect } from "react";
import { FaPlay, FaPause } from "react-icons/fa";
import styles from "./../App.module.css";

function Voice({
  sender,
  sender_profile,
  url,
  formatTimeDifference,
  server_duration,
  next_item_also_was_this_sender,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [duration, setDuration] = useState(0);

  const stop = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };
  const handleTogglePlay = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
      console.log({ a: audioRef.current, aaa: audioRef.current.duration });
    } else {
      stop();
    }
  };

  const [playPercentage, setPlayPercentage] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    const updateTime = () => {
      const currentTime = audio.currentTime;
      console.log({ currentTime });
      const duration =
        audio.duration && audio.duration != Infinity
          ? audio.duration
          : server_duration;
      setDuration(duration);

      // Calculate play percentage
      const newPlayPercentage = (currentTime / duration) * 100;
      setPlayPercentage(newPlayPercentage.toFixed(2)); // Round to two decimal places
    };

    // Attach the timeupdate event listener
    audio.addEventListener("timeupdate", updateTime);

    // Clean up the event listener when the component unmounts
    return () => {
      audio.removeEventListener("timeupdate", updateTime);
    };
  }, []);

  //   useEffect(() => {
  //     const audio = audioRef.current;

  //     if (audio) {
  //       const check_interval = setInterval(() => {
  //         console.log({ dddd: audio.duration });
  //         if (audio.duration != Infinity && audio.duration) {
  //           setDuration(audio.duration);
  //           clearInterval(check_interval);
  //         }
  //       }, 100);

  //       // Clean up event listeners when the component unmounts
  //       return () => {
  //         clearInterval(check_interval);
  //       };
  //     }
  //   }, []);
  let opratorExtraStyles = {};
  if (sender != "user") {
    opratorExtraStyles = {
      marginLeft:
        sender != "user" && next_item_also_was_this_sender == false ? 11 : 55,
    };
  }
  return (
    <div className={styles["message"]}>
      {sender != "user" && next_item_also_was_this_sender == false && (
        <div
          className={styles.photo}
          style={{
            position: "relative",
            backgroundImage: `url(${sender_profile.avatar})`,
          }}
        >
          {/* <div className={styles.online} /> */}
          <div className={styles.operator_name}>
            {sender_profile.first_name}
          </div>
        </div>
      )}

      <div
        className={`${styles.response} ${sender != "user" && styles.received}`}
        style={{ ...opratorExtraStyles }}
      >
        <div className={styles["audio-message"]}>
          <div
            className={styles["play-btn-container"]}
            onClick={handleTogglePlay}
          >
            {isPlaying ? (
              <FaPause
                style={{
                  boxSizing: "content-box",
                }}
              />
            ) : (
              <FaPlay
                style={{
                  boxSizing: "content-box",
                }}
              />
            )}
          </div>
          <div className={styles["play-bar-container"]}>
            <div className={styles["play-bar-holder"]}>
              <div className={styles["play-bar"]}>
                <div
                  className={styles["play-bar-filled"]}
                  style={{
                    width: `${playPercentage}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className={styles["play-time"]}>
              {duration && duration != Infinity
                ? formatTimeDifference(duration * 1000)
                : formatTimeDifference(server_duration * 1000)}
            </div>
          </div>
        </div>
        <audio
          onEnded={() => {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            setPlayPercentage(0);
            setIsPlaying(false);
          }}
          ref={audioRef}
          src={url}
        />
      </div>
    </div>
  );
}

export default Voice;
