export default {
  /**
   * local
   */
  SOCKET_HOST: "127.0.0.1",
  SOCKET_PORT: "6001",

  API_ENDPOINT: "http://localhost:3000",

  /**
   * test
   */
  SOCKET_HOST: "socket.staging.s4.zabanshenas.com",
  SOCKET_PORT: "443",
  API_ENDPOINT: "https://chat.staging.s4.zabanshenas.com",
  SOCKET_APP_KEY:
    "a7d7429db35460e746251264ed968011689b65e554cdec803579e6830a669b09",
  SOCKET_USE_TLS: true,
  // /**
  //  * local phone
  //  */
  // SOCKET_HOST: "192.168.2.126",
  // SOCKET_HOST: "127.0.0.1",

  // SOCKET_PORT: "6001",

  // API_ENDPOINT: "http://192.168.2.126:3000",

  // SOCKET_APP_KEY: "app-key",
};
