import React, { useState } from "react";
import styles from "./../App.module.css";

function OpratorPrev({ operator, currnet_index }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={styles["profile-prev"]}
      style={{
        height: 50,
        // width: 50,
        // background: "pink",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && (
        <div className={styles["profile-prev-title"]}>
          {operator.first_name}
          <div className={styles.tail}></div>
        </div>
      )}

      <img
        className={styles["profile-prev"]}
        src={operator.avatar}
        alt="Operator Avatar"
      />
    </div>
  );
}

export default OpratorPrev;
