import React from "react";
function replaceURLWithHTMLLinks(text) {
  var exp =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  var parts = text.split(exp);

  const result = parts.map((item) => {
    return {
      text: item,
      is_url: exp.test(item),
    };
  });
  return result.map((link) => {
    if (link.is_url) {
      return (
        <a
          target="_blank"
          style={{ direction: "ltr", display: "inline-block" }}
          href={link.text}
        >
          <p>{link.text}</p>
        </a>
      );
    }
    return <div style={{ display: "inline" }}>{link.text}</div>;
  });
}
function TextView({ text, html }) {
  if (html) {
    return html;
  }

  const pcs = text.split("\n");
  return (
    <React.Fragment>
      {pcs.map((line, index) => {
        return (
          <React.Fragment>
            {replaceURLWithHTMLLinks(line)}
            {pcs.length > index + 1 && <br></br>}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default TextView;
