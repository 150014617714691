import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";

import styles from "./../App.module.css";

function ImageView({ url, sender }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const onMouseEnter = () => {
    setIsHovered(true);
    // Add your additional logic for mouse enter here
  };

  const onMouseLeave = () => {
    setIsHovered(false);
    // Add your additional logic for mouse leave here
  };
  const downloadImage = () => {
    const link = document.createElement("a");

    // Set the link's href to the image source
    link.href = url;
    link.target = "_blank";

    // Set the link's download attribute to suggest a filename
    link.download = "image.jpg";

    // Append the link to the document
    document.body.appendChild(link);

    // Simulate a click on the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };
  let extraStyle = {};
  if (sender == "operator") {
    extraStyle = {
      marginLeft: 5,
      marginRight: "auto",
    };
  }
  return (
    <div className={`${styles.message} ${styles["text-only"]}`}>
      <div
        className={styles.response}
        style={{
          ...extraStyle,
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={styles["crisp-image-container"]}
          style={{ position: "relative" }}
        >
          {isImageLoaded == false && (
            <div
              style={{
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#e2e2e2", // Change this to the desired background color
                borderRadius: 11,
              }}
            >
              <AiOutlineLoading
                className={styles["image-loading"]}
                style={{
                  //   right: 14,
                  alignSelf: "center",
                  boxSizing: "content-box",
                  //   position: "absolute",
                  //   bottom: 0,
                }}
              ></AiOutlineLoading>
            </div>
          )}
          <img
            onLoad={() => {
              setIsImageLoaded(true);
            }}
            src={url}
            style={{
              display: isImageLoaded ? "block" : "none",
              opacity: isHovered ? 0.6 : 1,
              borderRadius: 6,
            }}
            className={`${styles["crisp-image"]} ${
              isHovered ? styles["hovered"] : ""
            }`}
          />
          {isHovered && isImageLoaded && (
            <div
              className={styles["download-image-overlay"]}
              style={{ position: "absolute" }}
              onClick={() => console.log("Download clicked")}
            >
              <div
                onClick={downloadImage}
                className={styles["download-image-btn"]}
              >
                دانلود
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageView;
