import React, { createContext, useState, useEffect, useRef } from "react";
const TabContext = createContext();
const TabContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState("chat");

  return (
    <TabContext.Provider value={[activeTab, setActiveTab]}>
      {children}
    </TabContext.Provider>
  );
};
export { TabContext, TabContextProvider };
