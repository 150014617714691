import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";
import "./fonts/Vazirmatn-Regular.ttf";
import { UploadFileContextProvider } from "./contexts/WordContext";
import { ScreenStateContextProvider } from "./contexts/ScreenStateContext";
import { TabContextProvider } from "./contexts/TabContext";

// Create a root element
const rootElement = document.createElement("div");
rootElement.id = "root";
document.body.appendChild(rootElement);

// Render the React app inside the root element
ReactDOM.render(
  <TabContextProvider>
    <ScreenStateContextProvider>
      <UploadFileContextProvider>
        <App />
      </UploadFileContextProvider>
    </ScreenStateContextProvider>
  </TabContextProvider>,
  rootElement
);

// Register the service worker
// registerServiceWorker();
