import { useContext } from "react";
import { ScreenStateContext } from "../contexts/ScreenStateContext";

const useScreenState = () => {
  const context = useContext(ScreenStateContext);

  if (!context) {
    throw new Error("useScreenState must be used within an AuthProvider");
  }

  return context;
};

export default useScreenState;
