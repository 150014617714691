import React, { useState, useEffect, useRef } from "react";
import { FaMicrophone } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import { IoTrash } from "react-icons/io5";
import configs from "../configs";
import useUploadingFiles from "../hooks/useWordGroups";
import { v4 as uuidv4 } from "uuid";

const { SOCKET_HOST, SOCKET_PORT, API_ENDPOINT } = configs;

const VoiceRecorder = React.forwardRef(
  (
    {
      setIsRecordingAudio,
      setStopRecording,
      setRecordingDurration,
      session_id,
      messages_count,
    },
    ref
  ) => {
    const [uploadingFiles, setUploadingFiles] = useUploadingFiles();
    const [duration, setDuration] = useState(null);
    const mediaRecorder = useRef();
    const [durationCalcInterval, setRecordingDurrationInterval] =
      useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);

    const initMediaRecorder = () => {
      if (!mediaRecorder.current && isPermissionGranted) {
        return new Promise((res, rej) => {
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(function (stream) {
              const recorder = new MediaRecorder(stream);

              recorder.ondataavailable = function (e) {
                if (e.data.size > 0) {
                  console.log({ aqqqudioPlayerSrc: e.data });
                  if (window.upload) {
                    window.upload(e.data);
                    window.upload = null;
                  }
                }
              };

              recorder.onstop = function () {
                mediaRecorder.current = undefined;
                stream
                  .getTracks() // get all tracks from the MediaStream
                  .forEach((track) => track.stop()); // stop each of them
              };

              mediaRecorder.current = recorder;
              res();
            })
            .catch(function (err) {
              rej(err);
              console.error("Error accessing microphone:", err);
            });
        });
      }
    };
    const startRecording = async () => {
      await initMediaRecorder();
      if (mediaRecorder.current) {
        const startedAt = Date.now();
        let _durationCalcInterval = setInterval(() => {
          console.log(Date.now() - startedAt);
          setRecordingDurration(Date.now() - startedAt);
          setDuration(Date.now() - startedAt);
        }, 50);
        setRecordingDurrationInterval(_durationCalcInterval);

        mediaRecorder.current.start();
        setIsRecording(true);
        setRecordingDurration(0);
      }
    };

    const stopRecording = () => {
      window.upload = null;

      if (mediaRecorder.current) {
        if (durationCalcInterval) {
          clearInterval(durationCalcInterval);
        }
        mediaRecorder.current.stop();
        setIsRecording(false);
      }
    };
    React.useImperativeHandle(ref, () => ({
      stopRecording,
      startRecording,
    }));
    useEffect(() => {
      // setStopRecording(stopRecording);
    }, [stopRecording]);
    useEffect(() => {
      setIsRecordingAudio(isRecording);
    }, [isRecording]);
    useEffect(() => {
      // Check microphone permission on component mount
      try {
        navigator.permissions
          .query({ name: "microphone" })
          .then((permissionStatus) => {
            setIsPermissionGranted(permissionStatus.state === "granted");
          });
      } catch (error) {
        setIsPermissionGranted(false);
      }
    }, []);

    const requestMicrophonePermission = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function () {
          setIsPermissionGranted(true);
        })
        .catch(function (err) {
          console.error("Microphone permission denied:", err);
        });
    };

    const onClick = () => {
      if (messages_count == 0) {
        return;
      }
      if (!isPermissionGranted) {
        return requestMicrophonePermission();
      }
      if (isPermissionGranted) {
        if (isRecording) {
          return stopRecording();
        } else {
          return startRecording();
        }
      }
    };
    const stopAndSend = () => {
      stopRecording();
      window.upload = (blob) => {
        console.log("upload", { blob });
        const formData = new FormData();
        formData.append("type", "audio");
        formData.append("session_id", session_id);
        formData.append("duration", duration / 1000);
        formData.append("file", blob, "filename.webm");

        // Make a POST request using Axios
        setUploadingFiles((old) => {
          return [
            ...old,
            {
              formData,
              type: "audio",
              duration: duration / 1000,
              id: uuidv4(),
            },
          ];
        });
      };
    };
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {isRecording && (
          <IoTrash
            onClick={stopRecording}
            style={{
              color: "red",
              alignSelf: "center",
              fontSize: 16,
              marginLeft: 20,
              padding: 0,
              borderRadius: "100%",
              cursor: "pointer",
              boxSizing: "content-box",
            }}
          ></IoTrash>
        )}
        {isRecording && (
          <MdSend
            onClick={stopAndSend}
            style={{
              color: "#427cf7",
              width: 25,
              fontSize: 35,
              // background: allowed_to_push_send ? "#053fd8" : "gray",
              padding: 9,
              borderRadius: "100%",
              marginRight: 9,
              cursor: "pointer",
              boxSizing: "content-box",

            }}
          ></MdSend>
        )}

        {isRecording == false && (
          <FaMicrophone
            onClick={onClick}
            style={{
              color: "#427cf7",
              color: isRecording ? "red" : "rgb(121 121 121)",

              width: 25,
              fontSize: 21,
              // background: allowed_to_push_send ? "#053fd8" : "gray",
              padding: 9,
              borderRadius: "100%",
              marginRight: 9,
              cursor: "pointer",
              boxSizing: "content-box",
            }}
          ></FaMicrophone>
        )}

        {/* {isRecording && <button onClick={stopRecording}>Stop Recording</button>} */}
        {/* {audioPlayerSrc && <button onClick={uploadRecording}>Upload</button>}
        {audioPlayerSrc && <audio controls src={audioPlayerSrc}></audio>} */}
      </div>
    );
  }
);

export default VoiceRecorder;
