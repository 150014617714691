import React, { useState, useEffect, useRef, Fragment } from "react";

import styles from "./App.module.css";
import { AiOutlineLoading } from "react-icons/ai";

import { HiOutlineEmojiHappy } from "react-icons/hi";
import { MdSend } from "react-icons/md";
import Pusher from "pusher-js";
import FloatingButton from "./components/FloatingButton";
import _ from "lodash";
import Emojies from "./components/Emojies";

import VoiceRecorder from "./components/VoiceRecorder";
import { PiFileTextDuotone } from "react-icons/pi";

import Voice from "./components/Voice";
import UploadFile from "./components/UploadFile";
import ImageView from "./components/ImageView";
import configs from "./configs";
import useUploadingFiles from "./hooks/useWordGroups";
import UploadingFileProgress from "./components/UploadingFileProgress";
import SendingFileMessage from "./components/SendingFileMessage";
import useScreenState from "./hooks/useScreenState";
import OpratorPrev from "./components/OpratorPrev";
import SwitchType from "./components/SwitchType";
import useTab from "./hooks/useTab";
import Faq from "./components/Faq";
import TextView from "./components/TextView";

import EmailForm from "./components/EmailForm";
import RateButtons from "./components/RateButtons";
import Rate from "./components/Rate";
import SeenHandler from "./components/SeenHandler";

const { SOCKET_HOST, SOCKET_PORT, API_ENDPOINT } = configs;

function formatTimeDifference(timeDifferenceInMillis) {
  // Convert milliseconds to seconds
  const totalSeconds = Math.floor(timeDifferenceInMillis / 1000);

  // Calculate minutes and seconds
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Extract milliseconds and format as "00"
  const milliseconds = Math.floor((timeDifferenceInMillis % 1000) / 10);

  // Format as "00:00,00"
  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")},${String(milliseconds).padStart(2, "0")}`;

  return formattedTime;
}
// import "bootstrap/dist/css/bootstrap.mincss";
function isFirstCharEnglish(str) {
  // Check if the string is not empty
  if (str && str.length > 0) {
    // Use a regular expression to check if the first character is an English letter
    const regex = /^[a-zA-Z]/;
    return regex.test(str);
  }

  return false;
}

function convertUnixTimestamp(timestamp) {
  const currentTime = new Date();
  const timestampDate = new Date(timestamp);
  const timeDifference = currentTime - timestampDate;
  if (timeDifference < 60000) {
    return "just now";
  } else if (timeDifference < 3600000) {
    const minutes = Math.floor(timeDifference / 60000);
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else if (timeDifference < 86400000) {
    const hours = Math.floor(timeDifference / 3600000);
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else {
    const days = Math.floor(timeDifference / 86400000);
    return `${days} day${days > 1 ? "s" : ""} ago`;
  }
}

const PREFIX = "unofficial_crisp_client_";
const SESSION_KEY_NAME = PREFIX + "session_id";
const getDirectionByString = (text) => {
  return isFirstCharEnglish(text) ? "ltr" : "rtl";
};
const App = () => {
  const [screenState, setScreenState] = useScreenState();

  useEffect(() => {
    // Define the media query
    const mediaQuery = window.matchMedia(
      "(max-height: 39.9375rem), (max-width: 39.9375rem)"
    );

    // Function to handle changes in the media query
    const handleMediaQueryChange = (event) => {
      setTimeout(() => {
        console.log("handle media quewry change ", window.lock_maximized);
        if (window.lock_maximized == true) {
          setScreenState("fullscreen");
        } else {
          setScreenState(event.matches ? "fullscreen" : "floating");
        }
      }, 50);
    };

    // Initial check and add event listener
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addListener(handleMediaQueryChange);

    // Clean up the event listener on component unmount
    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const [apiResponse, setAPIResponse] = useState("");
  const [hasConversation, setHasConversation] = useState(false);
  const [session_id, setSessionId] = useState(false);
  const [selectingEmoji, setIsSelectingEmoji] = useState(false);
  const [messages, setMessages] = useState([]);
  const [pendingMesasges, setPendingMessages] = useState([]);
  const chatViewRef = useRef();
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const loadHistory = async (_session_id, checkpoint) => {
    setIsLoadingHistory(true);
    window.last_loading_crisp_history = Date.now() / 1000;

    const url = `${API_ENDPOINT}/request-data`;
    const data = {
      session_id: _session_id,
      types: [
        { type: "history", checkpoint: checkpoint ?? Date.now() },
        { type: "operator_list" },
      ],
    };

    try {
      const history_result = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      console.log({ history_result });
    } catch (error) {
      console.log(error);
    }
  };
  const syncParams = async () => {
    if (window.CUSTOM_CRISP_READY_TRIGGER) {
      const {
        phone,
        email,
        nickname,
        app_version,
        app_name,
        hash,
        session_id: session_id_from_params,
      } = window.CUSTOM_CRISP_READY_TRIGGER();

      const url = `${configs.API_ENDPOINT}/request-data`;
      const data = {
        session_id: session_id,
        types: [
          {
            type: "sync_params",
            sync_data: {
              phone,
              email,
              nickname,
              app_version,
              app_name,
              hash,
              // chat_type: window.lock_maximized ? "غیر شناور" : "شناور",
              // domain: window?.location?.origin,
              origin: window.lock_maximized ? "app" : "site",
            },
          },
        ],
      };

      try {
        const history_result = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
        console.log({ history_result });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const afterSocketConnected = () => {
    if (session_id) {
      loadHistory(session_id);
      syncParams();
    }
  };
  useEffect(() => {
    if (window.CUSTOM_CRISP_READY_TRIGGER) {
      const callback_result = window.CUSTOM_CRISP_READY_TRIGGER();
      const {
        phone,
        email,
        nickname,
        app_version,
        app_name,
        hash,
        session_id: session_id_from_params,
      } = callback_result;
      if (session_id_from_params) {
        setSessionId(session_id_from_params);
        setHasConversation(true);
        return;
      }
    }
    const session_id_from_storage = localStorage.getItem(SESSION_KEY_NAME);
    if (!!session_id_from_storage) {
      setSessionId(session_id_from_storage);
      setHasConversation(true);
    }
  }, []);
  useEffect(() => {
    const url = `${API_ENDPOINT}/init-data`;

    try {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          const { operator_list } = response;
          console.log({ operator_list });
          setOperatorList(operator_list);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    console.log("scrollIntoView");
    console.log({
      crisp_after_load_history: window.crisp_after_load_history,
    });
    if (window.crisp_after_load_history) {
      setTimeout(() => {
        window.crisp_after_load_history = false;
      }, 500);
    } else {
      chatViewRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, pendingMesasges]);
  const [OperatorList, setOperatorList] = useState([]);

  useEffect(() => {
    if (!session_id) return;
    let client = new Pusher(configs.SOCKET_APP_KEY, {
      wsHost: SOCKET_HOST,
      cluster: "ir",
      wsPort: SOCKET_PORT,
      forceTLS: configs.SOCKET_USE_TLS,
      encrypted: true,
      disableStats: true,
      enabledTransports: ["ws", "wss"],
    });

    client.connection.bind("connected", () => {
      afterSocketConnected();
    });
    const connection = client.subscribe(session_id);

    connection.bind("session::resolved", ({ ask_to_rate }) => {
      alert(JSON.stringify({ ask_to_rate }));
    });
    connection.bind("session::clear", () => {
      console.log({ SESSION_KEY_NAME: SESSION_KEY_NAME });
      localStorage.removeItem(SESSION_KEY_NAME);
      setSessionId(null);
    });
    connection.bind("operator_list", (operator_list) => {
      setOperatorList(operator_list);
    });
    const onMessageRecived = (_message) => {
      const deny_list = ["event"];
      setMessages((old) => {
        const rows = _.sortBy([...old, ..._message], "timestamp").filter(
          ({ type }) => deny_list.includes(type) == false
        );
        return _.uniqBy(rows, (row) => {
          return row.timestamp + "-" + row.fingerprint;
        });
      });

      setPendingMessages((old) => {
        const newMessages = _message.map((item) => item.message);
        return old.filter((item) => {
          const res = newMessages.includes(item.message);
          if (res) {
            // Remove the item from newMessages
            const index = newMessages.indexOf(item.message);
            newMessages.splice(index, 1);
          }
          return !res;
        });
      });
    };

    connection.bind("message::update", (_message) => {
      setMessages((old) => {
        return old.map((item) => {
          if (item.fingerprint == _message[0].fingerprint) {
            item.message = _message[0].message;
          }
          return item;
        });
      });
    });

    connection.bind("message::remove", (_message) => {
      const removed_ids = _message.map((item) => item.fingerprint);
      setMessages((old) => {
        return old.filter((item) => {
          return removed_ids.includes(item.fingerprint) == false;
        });
      });
    });

    connection.bind("message::history", (_message) => {
      setIsLoadingHistory(false);
      onMessageRecived(_message);
    });
    connection.bind("message", onMessageRecived);
  }, [session_id]);
  const [chatIsVisible, setChatIsVisible] = useState(false);
  const onActiveChanged = (status) => {
    setChatIsVisible(status);
  };
  const [message, setMessage] = useState("");
  const [uploadingFiles, setUploadingFiles] = useUploadingFiles();
  const [canSendMessage, setCanSendMessage] = useState(true);
  const [askForEmail, setIsAskingForEmail] = useState({
    is: false,
    message: [],
  });
  const createConversation = async ({ message: _message, contact_info }) => {
    setCanSendMessage(false);

    const url = `${API_ENDPOINT}/post-message`;
    const data = {
      type: "create_conversation",
      data: {
        message: _message,
        contact_info: contact_info,
      },
    };

    const create_conversation_result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    setCanSendMessage(true);
    setHasConversation(true);
    const { session_id } = await create_conversation_result.json();
    setSessionId(session_id);
    if (session_id) {
      localStorage.setItem(SESSION_KEY_NAME, session_id);
    }
    setIsAskingForEmail(false);
    loadHistory();
    console.log({
      create_conversation_result: session_id,
    });
  };

  const sendMessage = async () => {
    const _message = message.slice();
    if (_message.trim().length == 0) {
      return;
    }

    if (canSendMessage == false) return;

    if (hasConversation == false && messages.length == 0) {
      const params = window.location.href?.split("?")[1]?.split("&");
      const result = {};
      if (params) {
        for (const item of params) {
          const key = item.split("=")[0];
          const value = item.split("=")[1];
          if (key === "phone") {
            result.phone = value;
          }
          if (key === "email") {
            result.email = decodeURIComponent(value);
          }
        }
      }
      if (result.email || result.phone) {
      } else {
        setIsAskingForEmail({
          is: true,
          message: [message],
        });
        return setMessage("");
      }
    }
    setMessage("");

    if (hasConversation == false) {
      return await createConversation({
        message: _message,
      });
    } else {
      setPendingMessages((old) => {
        return [
          ...old,
          {
            type: "text",
            message: _message,
            sender: "user",
          },
        ];
      });

      const url = `${API_ENDPOINT}/post-message`;
      const data = {
        type: "message",
        data: {
          message: _message,
          session_id: session_id,
        },
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("Result:", result);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const [recordingAudio, setIsRecordingAudio] = useState(false);
  const [recordingDurration, setRecordingDurration] = useState(0);
  const allowed_to_push_send = message.trim().length > 0 && canSendMessage;

  const recorderRef = useRef(null);
  const [activeTab, setActiveTab] = useTab();

  const [isAtTop, setIsAtTop] = useState(true);
  const [isRating, setIsRating] = useState(false);
  useEffect(() => {
    const sortedMessages = _.sortBy(messages, "timestamp");
    if (session_id && sortedMessages.length > 0) {
      const oldest_record = sortedMessages[0];

      const now = Date.now() / 1000;
      const diff = now - window.last_loading_crisp_history;
      if (diff > 2) {
        window.crisp_after_load_history = true;
        loadHistory(session_id, oldest_record.timestamp);
      }
    }
  }, [isAtTop]);
  const targetDivRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const atTop = targetDivRef.current.scrollTop === 0;
      setIsAtTop(atTop);
    };

    // Add event listener to detect scroll on the target div
    targetDivRef.current.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      targetDivRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [targetDivRef.current]);

  const WELCOME_MESSAGE = "سلام.تیم پشتیبانی زبانشناس در خدمت شماست.";

  const [lastSeen, setLastSeen] = useState(0);
  const sendSeenTimeout = useRef();
  const onSeen = async (current_last_seen) => {
    if (current_last_seen > lastSeen) {
      setLastSeen(current_last_seen);
      if (sendSeenTimeout.current) {
        clearTimeout(sendSeenTimeout.current);
      }
      sendSeenTimeout.current = setTimeout(async () => {
        const url = `${API_ENDPOINT}/request-data`;
        const data = {
          session_id: session_id,
          types: [{ type: "seen", last_seen_fingerprint: [current_last_seen] }],
        };

        try {
          const seen_result = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          console.log({ seen_result });
        } catch (error) {
          console.log(error);
        }
      }, 500);
    }
  };

  const [lastDelivered, setLastDelivered] = useState(0);
  const sendDeliverRef = useRef();

  const onMessageDelivered = (fingerprint) => {
    if (fingerprint > lastDelivered) {
      setLastDelivered(fingerprint);
      if (sendDeliverRef.current) {
        clearTimeout(sendDeliverRef.current);
      }
      sendDeliverRef.current = setTimeout(async () => {
        const url = `${API_ENDPOINT}/request-data`;
        const data = {
          session_id: session_id,
          types: [{ type: "deliver", last_deliver_fingerprint: [fingerprint] }],
        };

        try {
          const seen_result = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
          console.log({ seen_result });
        } catch (error) {
          console.log(error);
        }
      }, 500);
    }
  };
  useEffect(() => {
    if (messages.length > 0) {
      onMessageDelivered(messages[messages.length - 1].fingerprint);
    }
  }, [messages]);
  return (
    <div style={{ direction: "ltr" }}>
      <FloatingButton onActiveChanged={onActiveChanged}></FloatingButton>
      <div
        style={{
          display: chatIsVisible ? "block" : "none",
        }}
        className={`${styles["container"]}  ${
          screenState == "fullscreen"
            ? styles["fullscreen-chat"]
            : styles["floating-chat"]
        } `}
      >
        {chatIsVisible && screenState == "fullscreen" && (
          <style>
            {`
          
          html {
              overflow: hidden;
          }
          `}
          </style>
        )}
        <div className={styles.row}>
          <section className={styles.chat}>
            <div
              className={styles["header-chat"]}
              style={{
                position: "relative",
                display: "flex",
                overflow: "hidden",
                justifyContent: "space-around",
                flexDirection: "column",
              }}
            >
              <div
                className={styles["rotating-element"]}
                style={{
                  background: "#2b6fef",
                  position: "absolute",
                  backgroundRepeat: "repeat",
                  backgroundSize: 40,
                  width: "2000%",
                  height: "2000%",
                  opacity: 0.2,
                }}
              ></div>

              <div
                style={{
                  //   background: "red",
                  zIndex: 1,
                  height: 45,
                  width: "100%",
                  maxWidth: 425,
                }}
              >
                <div style={{ zIndex: 1, textAlign: "center" }}>
                  <SwitchType
                    setIsRating={setIsRating}
                    activeTab={activeTab}
                    session_id={session_id}
                    hasConversation={hasConversation}
                  ></SwitchType>
                </div>
                <div
                  style={{
                    // position: "relative",
                    // flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: 10,
                    // marginTop: -4,
                  }}
                >
                  {OperatorList.map((operator, index) => {
                    return (
                      <OpratorPrev
                        key={operator.user_id}
                        operator={operator}
                        currnet_index={index}
                      ></OpratorPrev>
                    );
                  })}
                </div>
              </div>
              <div style={{ zIndex: 1, textAlign: "center", marginTop: 8 }}>
                <p
                  style={{
                    fontFamily: "IRANSansX",
                    color: "#fff",
                  }}
                  className={styles.name}
                >
                  سوالات خود را از من بپرسید
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "IRANSansX",
                      color: "rgb(232 232 232)",
                      fontSize: 10,
                    }}
                    className={styles.name}
                  >
                    معمولا در کمتر از یک ساعت پاسخ میدهیم
                  </p>

                  <span
                    style={{
                      height: 8,
                      width: 8,
                      display: "inline-block",
                      background: "#4ece3d",
                      borderRadius: "100%",
                      marginLeft: 2,
                    }}
                  ></span>
                </div>
              </div>
            </div>

            {uploadingFiles.map((item) => {
              return (
                <UploadingFileProgress item={item}></UploadingFileProgress>
              );
            })}

            {isRating && (
              <Rate
                messages={messages}
                session_id={session_id}
                onClose={() => {
                  setIsRating(null);
                }}
                selectedRate={isRating}
              ></Rate>
            )}

            {activeTab == "chat" && (
              <div ref={targetDivRef} className={styles["messages-chat"]}>
                {selectingEmoji && (
                  <Emojies
                    onItemSelect={(item) => {
                      setIsSelectingEmoji(false);
                      setMessage((old) => old + item);
                    }}
                  ></Emojies>
                )}
                {isLoadingHistory && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <AiOutlineLoading
                      className={styles["image-loading"]}
                      style={{
                        alignSelf: "center",
                      }}
                    ></AiOutlineLoading>
                  </div>
                )}

                {hasConversation == false && (
                  <React.Fragment>
                    <div className={styles["message"]}>
                      <div
                        className={styles["photo"]}
                        style={{
                          position: "relative",
                          backgroundImage: `url(${OperatorList?.[0]?.avatar})`,
                        }}
                      >
                        <div className={styles.operator_name}>
                          {OperatorList?.[0]?.first_name}
                        </div>
                        {/* <div
                          className={
                            OperatorList?.[0]?.availability == "online"
                              ? styles.online
                              : styles.offline
                          }
                        /> */}
                      </div>

                      <p
                        style={{
                          direction: getDirectionByString(WELCOME_MESSAGE),
                        }}
                        className={`${styles["text"]} ${styles["chat-left"]}`}
                      >
                        <TextView text={WELCOME_MESSAGE} />
                      </p>
                    </div>
                  </React.Fragment>
                )}

                {askForEmail.is && (
                  <EmailForm
                    createConversation={createConversation}
                    askForEmail={askForEmail}
                  ></EmailForm>
                )}

                {messages.map((msg, index) => {
                  const key = index + "-" + msg.message;
                  if (msg.type == "file") {
                    if (msg.message.type.includes("image")) {
                      return (
                        <ImageView
                          sender={msg.sender}
                          url={msg.message.url}
                        ></ImageView>
                      );
                    }

                    return (
                      <div key={key}>
                        <div
                          className={`${styles.message} ${styles["text-only"]}`}
                        >
                          <div className={styles.response}>
                            <p className={styles.text}>
                              <TextView
                                html={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <PiFileTextDuotone
                                      style={{
                                        fontSize: 40,
                                        alignSelf: "center",
                                      }}
                                    ></PiFileTextDuotone>
                                    <div style={{ alignSelf: "center" }}>
                                      فایل ارسال شد
                                    </div>
                                  </div>
                                }
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  let last_item_also_was_this_sender = false;
                  let next_item_also_was_this_sender = false;
                  if (index > 0) {
                    last_item_also_was_this_sender =
                      messages[index - 1].sender == msg.sender;
                  }
                  if (index + 1 < messages.length) {
                    next_item_also_was_this_sender =
                      messages[index + 1]?.sender_profile?.user_id ==
                      msg?.sender_profile?.user_id;
                    // console.log({ msg });
                    // if(next_item_also_was_this_sender){
                    //   msg.sender_profile.user_id==
                    // }
                  }
                  if (msg.type == "audio") {
                    return (
                      <Voice
                        next_item_also_was_this_sender={
                          next_item_also_was_this_sender
                        }
                        sender_profile={msg.sender_profile}
                        sender={msg.sender}
                        url={msg.message.url}
                        server_duration={msg.message.duration}
                        formatTimeDifference={formatTimeDifference}
                      ></Voice>
                    );
                  }

                  if (msg.sender == "user") {
                    return (
                      <React.Fragment key={key}>
                        <div
                          className={`${styles.message} ${styles["text-only"]}`}
                        >
                          <div className={styles.response}>
                            <p
                              style={{
                                direction: getDirectionByString(msg.message),
                              }}
                              className={styles.text}
                            >
                              <TextView text={msg.message} />
                            </p>
                          </div>
                        </div>
                        {next_item_also_was_this_sender == false && (
                          <p
                            className={`${styles["response-time"]} ${styles.time}`}
                          >
                            {convertUnixTimestamp(msg.timestamp)}
                          </p>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={key}>
                        <SeenHandler msg={msg} onSeen={onSeen}></SeenHandler>
                        <div
                          className={
                            !next_item_also_was_this_sender
                              ? `${styles.message}`
                              : `${styles.message} ${styles["text-only"]}`
                          }
                        >
                          {next_item_also_was_this_sender == false && (
                            <div
                              className={styles.photo}
                              style={{
                                position: "relative",
                                backgroundImage: `url(${msg.sender_profile.avatar})`,
                              }}
                            >
                              <div className={styles.operator_name}>
                                {msg.sender_profile.first_name}
                              </div>
                              {/* <div
                                className={
                                  msg.sender_profile.availability == "online"
                                    ? styles.online
                                    : styles.offline
                                }
                              /> */}
                            </div>
                          )}
                          <p
                            style={{
                              direction: getDirectionByString(msg.message),
                            }}
                            className={`${styles.text} ${styles["chat-left"]}`}
                          >
                            <TextView text={msg.message} />
                          </p>
                        </div>
                        {next_item_also_was_this_sender == false && (
                          <p className={styles.time}>
                            {convertUnixTimestamp(msg.timestamp)}
                          </p>
                        )}
                      </React.Fragment>
                    );
                  }
                })}
                {pendingMesasges.map((msg, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={`${styles.message} ${styles["text-only"]}`}
                      >
                        <div
                          className={`${styles.response}`}
                          style={{
                            position: "relative",
                          }}
                        >
                          <p
                            style={{
                              direction: getDirectionByString(msg.message),
                            }}
                            className={styles.text}
                          >
                            <TextView text={msg.message} />
                          </p>
                          <AiOutlineLoading
                            className={styles["rotate-pending-message"]}
                            style={{
                              right: 14,
                              position: "absolute",
                              bottom: 0,
                              boxSizing: "content-box",
                              width: 10,
                            }}
                          ></AiOutlineLoading>
                        </div>
                      </div>
                      {/* {next_item_also_was_this_sender == false && (
                      <p className="response-time time">
                        {convertUnixTimestamp(msg.timestamp)}
                      </p>
                    )} */}
                    </React.Fragment>
                  );
                })}
                {/* <div className="message text-only">
                <div className="response">
                  <p className="text"> Hey Megan ! It's been a while 😃</p>
                </div>
              </div> */}
                {/* {Array(40)
                .fill(null)
                .map((item) => {
                  return (
                    <div className="message text-only">
                      <div className="response">
                        <p className="text">
                          {" "}
                          سلام خوبی؟ چطوری؟ امروز خواستم مورد جدیدی رو تست کنم
                        </p>
                      </div>
                    </div>
                  );
                })} */}
                {/* <p className="response-time time"> 15h04</p> */}
                <div ref={chatViewRef}></div>
              </div>
            )}
            {activeTab == "faq" && <Faq></Faq>}
            <div
              className={styles["footer-chat"]}
              style={{
                justifyContent: "space-between",
                flexDirection: "row-reverse",
                position: "relative",
              }}
            >
              <SendingFileMessage></SendingFileMessage>
              {recordingAudio && (
                <div
                  onClick={() => {
                    if (recorderRef.current) {
                      recorderRef.current.stopRecording();
                    }
                  }}
                  style={{
                    width: "78%",
                    height: "58%",
                    position: "absolute",
                    background: "white",
                    left: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 20,
                      fontSize: "small",
                      fontFamily: "IRANSansX",
                    }}
                  >
                    {formatTimeDifference(recordingDurration)}
                  </div>
                  <div
                    style={{
                      direction: "rtl",
                      marginRight: 20,
                      fontFamily: "IRANSansX",
                    }}
                  >
                    در حال ضبط...
                  </div>

                  <div></div>
                </div>
              )}
              {activeTab == "chat" && (
                <div
                  style={{
                    justifyContent: "space-between",
                    flexDirection: "row-reverse",
                    display: "flex",
                    width: "100%",
                    height: 80,
                    display: "flex",
                    alignItems: "center",
                    /* position: absolute; */
                    bottom: 0,
                    backgroundColor: "transparent",
                    borderTop: "2px solid #EEE",
                  }}
                >
                  {message.length == 0 && (
                    <VoiceRecorder
                      messages_count={messages.length}
                      session_id={session_id}
                      setRecordingDurration={setRecordingDurration}
                      ref={recorderRef}
                      setIsRecordingAudio={setIsRecordingAudio}
                    ></VoiceRecorder>
                  )}
                  {message.length > 0 && (
                    <MdSend
                      onClick={sendMessage}
                      style={{
                        color: "#427cf7",
                        width: 25,
                        fontSize: 35,
                        // background: allowed_to_push_send ? "#053fd8" : "gray",
                        padding: 9,
                        borderRadius: "100%",
                        marginRight: 9,
                        cursor: "pointer",
                        boxSizing: "content-box",
                      }}
                    ></MdSend>
                  )}
                  <HiOutlineEmojiHappy
                    onClick={() => {
                      setIsSelectingEmoji(!selectingEmoji);
                    }}
                    style={{
                      fontSize: 25,
                      marginLeft: 5,
                      color: "#bababa",
                      color: "rgb(121 121 121)",
                      cursor: "pointer",
                      // width: "10%",
                      boxSizing: "content-box",
                    }}
                  ></HiOutlineEmojiHappy>
                  <input
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        sendMessage();
                      }
                    }}
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className={styles["write-message"]}
                    style={{
                      // width: "70%",
                      flex: 1,
                      fontSize: 16,
                      direction: getDirectionByString(message),
                    }}
                    placeholder="پیام خود را اینجا بنویسید..."
                  />
                  <UploadFile
                    messages_count={messages.length}
                    session_id={session_id}
                  ></UploadFile>
                </div>
              )}
            </div>
            {/* <div>file / audio</div> */}
          </section>
        </div>
      </div>
    </div>
  );
};

export default App;
