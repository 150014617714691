import { useContext } from "react";
import { TabContext } from "../contexts/TabContext";

const useTab = () => {
  const context = useContext(TabContext);

  if (!context) {
    throw new Error("useTab must be used within an AuthProvider");
  }

  return context;
};

export default useTab;
