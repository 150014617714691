import React, { createContext, useState, useEffect, useRef } from "react";
const ScreenStateContext = createContext();
const ScreenStateContextProvider = ({ children }) => {
  const [screenState, setScreenState] = useState("fullscreen");
  useEffect(() => {
    console.log({ screenState });
  }, [screenState]);
  const setScreenStateProxy = (state) => {
    // if (window.lock_maximized == true) {
    //   setScreenState("fullscreen");
    //   return;
    // }
    setScreenState(state);
  };
  return (
    <ScreenStateContext.Provider value={[screenState, setScreenState]}>
      {children}
    </ScreenStateContext.Provider>
  );
};
export { ScreenStateContext, ScreenStateContextProvider };
