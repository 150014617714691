import React, { useEffect, useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { IoMdChatboxes } from "react-icons/io";
import useTab from "../hooks/useTab";
import RateButtons from "./RateButtons";
import styles from "./../App.module.css";
import { IoClose } from "react-icons/io5";
import useScreenState from "../hooks/useScreenState";
function SwitchType({ session_id, setIsRating, activeTab, hasConversation }) {
  const [activeItem, setActiveItem] = useTab();

  const [screenState, setScreenState] = useScreenState();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          background: "rgb(0 0 0 / 20%)",
          display: "flex",
          borderRadius: 9,
        }}
      >
        <div
          onClick={() => {
            setActiveItem("faq");
          }}
          className={`${styles["switch-item"]} ${
            activeItem == "faq" ? styles.active : ""
          }`}
        >
          <p
            style={{
              fontFamily: "IRANSansX",
              color: "#fff",
            }}
            className={styles.name}
          >
            سوالات متداول
            <FaRegQuestionCircle
              style={{
                alignSelf: "center",
                marginLeft: 3,
                boxSizing: "content-box",
              }}
            />
          </p>
        </div>
        <div
          onClick={() => {
            setActiveItem("chat");
          }}
          className={`${styles["switch-item"]} ${
            activeItem == "chat" ? styles.active : ""
          }`}
        >
          <p
            style={{
              fontFamily: "IRANSansX",
              color: "#fff",
            }}
            className={styles.name}
          >
            چت
            <IoMdChatboxes
              style={{
                alignSelf: "center",
                marginLeft: 3,
                boxSizing: "content-box",
              }}
            />
          </p>
        </div>
      </div>
      {activeTab == "chat" && hasConversation && (
        <RateButtons
          session_id={session_id}
          setIsRating={setIsRating}
        ></RateButtons>
      )}
      {screenState == "fullscreen" && window.lock_maximized != true && (
        <div
          onClick={() => {
            if (window.close_crisp) {
              window.close_crisp();
            }
          }}
          style={{
            position: "absolute",
            right: 18,
            top: 20,
            display: "flex",
            zIndex: 999999,
            cursor: "pointer",
          }}
        >
          <IoClose
            style={{
              alignSelf: "center",
              marginLeft: 3,
              color: "white",
              boxSizing: "content-box",
              fontSize: 20,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default SwitchType;
