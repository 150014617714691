import React, { useEffect, useState } from "react";

import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import configs from "../configs";
import useScreenState from "../hooks/useScreenState";
import { AiOutlineLoading } from "react-icons/ai";
import styles from "./../App.module.css";

function Rate({ selectedRate, onClose, session_id, messages }) {
  const close = () => {
    onClose();
  };

  const [comment, setComment] = useState("");
  const [isSending, setIsSending] = useState(false);
  const send = async () => {
    if (isSending) return;
    let last_operator;
    try {
      last_operator = messages
        .filter((item) => {
          return item.sender == "operator";
        })
        .reverse()[0].sender_profile;

      last_operator = (
        last_operator.first_name +
        " " +
        last_operator.last_name
      ).trim();
    } catch (error) {
      console.log({ error });
    }
    let user_id = null;

    try {
      const params = window.location.href?.split("?")[1]?.split("&");

      const id = params.find((item) => {
        const key = item.split("=")[0];
        if (key == "id") {
          return true;
        }
      });
      if (id) {
        const user_id_from_params = id.split("=")[1];
        user_id = user_id_from_params;
      }

      console.log({ params });
    } catch (error) {}
    setIsSending(true);
    const url = `${configs.API_ENDPOINT}/request-data`;
    const data = {
      session_id: session_id,
      types: [
        {
          type: "rate",
          last_operator,
          rate_domain: window.location.href,
          user_id,
          comment: comment,
          selected_rate: selectedRate,
        },
      ],
    };

    try {
      const rate_result = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      localStorage.setItem(session_id + "-rate", selectedRate);
      console.log({ rate_result });
    } catch (error) {
      console.log(error);
    }

    setTimeout(() => {
      close();
      setIsSending(false);
    }, 1000);
  };
  return (
    <div className={styles["rate-container"]}>
      <div
        className={styles["rate-shadow"]}
        style={{
          marginTop: "5%",
          position: "absolute",
          background: "white",
          alignSelf: "center",
          textAlign: "start",
          direction: "rtl",
          padding: "13px 30px 16px 30px",
          borderRadius: 20,
          fontFamily: "IRANSansX",
          fontSize: 13,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          width: "80%",
        }}
      >
        <div
          className={styles["rate-selected"]}
          style={{
            marginTop: 15,
            top: 12,
            right: 14,
          }}
        >
          <div
            onClick={close}
            style={{
              position: "absolute",
              top: 12,
              right: 14,
              cursor: "pointer",
            }}
          >
            <MdClose
              style={{
                boxSizing: "content-box",
              }}
            ></MdClose>
          </div>
          {selectedRate == "like" && (
            <BiLike
              style={{
                alignSelf: "center",

                boxSizing: "content-box",
              }}
            ></BiLike>
          )}
          {selectedRate == "dislike" && (
            <BiDislike
              style={{ alignSelf: "center", boxSizing: "content-box" }}
            ></BiDislike>
          )}
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            fontSize: 15,
            marginTop: 5,
          }}
        >
          <div>
            ممنون از اینکه به ما رتبه دادید شما همچنین میتوانید نظر خود را برای
            ما ارسال کنید.
          </div>
        </div>

        <textarea
          onChange={(e) => {
            setComment(e.target.value);
          }}
          cols="40"
          rows="5"
          style={{ direction: "rtl", width: "100%", resize: "none" }}
          placeholder="نظر شما..."
        >
          {comment}
        </textarea>
        <div onClick={send} className={styles["sumbit-comment"]}>
          {!isSending && <span style={{ alignSelf: "center" }}>ثبت نظر</span>}
          {isSending && (
            <AiOutlineLoading
              className={styles["rotate-pending-message"]}
              style={{
                alignSelf: "center",
                boxSizing: "content-box",
              }}
            ></AiOutlineLoading>
          )}
        </div>
      </div>
    </div>
  );
}

export default Rate;
