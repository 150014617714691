import React, { useEffect, useState } from "react";
import configs from "../configs";
import useUploadingFiles from "../hooks/useWordGroups";

function UploadingFileProgress(props) {
  const { id, type, formData } = props.item;
  const { SOCKET_HOST, SOCKET_PORT, API_ENDPOINT } = configs;

  const [uplloadingFiles, setUploadingFiles] = useUploadingFiles();
  const [status, setStatus] = useState("pending");
  useEffect(() => {
    setStatus("uploading");
    fetch(`${API_ENDPOINT}/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        setUploadingFiles((old) => {
          return old.filter((item) => {
            return item.id != id;
          });
        });
        setStatus("done");
        console.log("File uploaded successfully:", data);
      })
      .catch((error) => {
        setStatus("error");
        console.error("Error uploading file:", error);
      });
  }, []);
  return <div></div>;
}

export default UploadingFileProgress;
