import React, { useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import styles from "./../App.module.css";

function Faq(props) {
  const [faq, setFaq] = useState([]);
  const getFaq = async (_session_id) => {
    const url = `${API_ENDPOINT}/request-data`;
    const data = {
      session_id: _session_id,
      types: [{ type: "faq" }],
    };

    try {
      const history_result = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      console.log({ history_result });
    } catch (error) {
      console.log(error);
    }
  };
  const [loading, setLoading] = useState(true);
  return (
    <div
      style={{
        height: "auto",
        flex: 1,
        minHeight: 480,
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            pointerEvents: "none",
          }}
        >
          <AiOutlineLoading
            className={styles["rotate-pending-message"]}
            style={{
              alignSelf: "center",
              marginBottom: 120,
              boxSizing: "content-box",
              fontSize: 21,
            }}
          ></AiOutlineLoading>
        </div>
      )}
      <iframe
        onLoad={() => {
          setLoading(false);
        }}
        style={{
          width: "100%",
        }}
        src="https://help.zabanshenas.com/fa/"
      ></iframe>
    </div>
  );
}

export default Faq;
