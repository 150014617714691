import React, { useEffect, useState } from "react";
import useUploadingFiles from "../hooks/useWordGroups";

import { v4 as uuidv4 } from "uuid";
import styles from "./../App.module.css";

function SendingFileMessage(props) {
  const [uploadingFiles] = useUploadingFiles();
  const [number, setNumber] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Increment the number
      setNumber((prevNumber) => (prevNumber + 1) % 3);
    }, 500);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [number]); // Re-run effect whenever 'number' changes
  return (
    <React.Fragment>
      {uploadingFiles.length > 0 && (
        <div className={styles["sending-file"]}>
          {[
            ".".repeat(number),
            " در حال ارسال",
            uploadingFiles.length,
            "فایل",
          ].join(" ")}
        </div>
      )}
    </React.Fragment>
  );
}

export default SendingFileMessage;
