import React, { useEffect, useState } from "react";
import { BiDislike } from "react-icons/bi";
import { BiLike } from "react-icons/bi";
import styles from "./../App.module.css";

const RateButton = ({ selectedRate, name, Icon, hoverMessage, onSelect }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isSelected = selectedRate == name;
  const onMouseEnter = () => {
    setIsHovered(true);
    // Add your additional logic for mouse enter here
  };

  const onMouseLeave = () => {
    setIsHovered(false);
    // Add your additional logic for mouse leave here
  };

  return (
    <div
      onClick={() => {
        onSelect(name);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={styles["rate-btn"]}
      style={{
        padding: 7,
        cursor: "pointer",
        borderRadius: 5,
        display: "flex",
        backgroundColor: isHovered || isSelected ? "#0108bb7d" : "transparent",
        justifyContent: "center",
      }}
    >
      {isHovered && (
        <div className={styles["rate-tooltip"]}>
          <div>{hoverMessage}</div>
        </div>
      )}
      <Icon
        style={{
          alignSelf: "center",
          fontSize: 20,
          color: "white",

          boxSizing: "content-box",
        }}
      />
    </div>
  );
};
function RateButtons({ setIsRating, session_id }) {
  const [selectedRate, setSelectedRate] = useState(null);
  const [shouldBrodcast, setShouldBrodcast] = useState(true);

  useEffect(() => {
    const has_rate = localStorage.getItem(session_id + "-rate");
    console.log({ has_rate });
    if (has_rate) {
      setShouldBrodcast(false);
      setSelectedRate(has_rate);
    }
  }, []);

  useEffect(() => {
    if (selectedRate) {
      if (shouldBrodcast) {
        setIsRating(selectedRate);
      }
    }
  }, [selectedRate, shouldBrodcast]);
  return (
    <div
      style={{
        position: "absolute",
        left: 19,
        // top: 10,
        display: "flex",
        zIndex: 999999,
      }}
    >
      <RateButton
        selectedRate={selectedRate}
        name={"dislike"}
        onSelect={(s) => {
          setShouldBrodcast(true);
          setSelectedRate(s);
        }}
        Icon={BiDislike}
        hoverMessage="!راضی نیستم"
      ></RateButton>
      <RateButton
        name={"like"}
        selectedRate={selectedRate}
        onSelect={(s) => {
          setShouldBrodcast(true);

          setSelectedRate(s);
        }}
        Icon={BiLike}
        hoverMessage="راضی هستم"
      ></RateButton>
      {/* <div
        className="rate-btn"
        style={{
          padding: 7,
          cursor: "pointer",
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="rate-tooltip">
          <div>راضی هستم</div>
        </div>
        <BiDislike
          style={{ alignSelf: "center", fontSize: 20, color: "white" }}
        />
      </div> */}
    </div>
  );
}

export default RateButtons;
