import React, { useEffect, useState } from "react";

import { RiChat2Fill } from "react-icons/ri";

import { IoCloseOutline } from "react-icons/io5";
import useScreenState from "../hooks/useScreenState";

function FloatingButton({ onActiveChanged }) {
  const [isActive, setIsActive] = useState(false);
  const [screenState] = useScreenState();
  useEffect(() => {
    if (window.lock_maximized) {
      onActiveChanged(true);
      setIsActive(true);
      return;
    }
    onActiveChanged(isActive);
    window.close_crisp = () => {
      setIsActive(false);
    };
  }, [isActive, screenState, setIsActive]);
  if (screenState == "fullscreen" && isActive) {
    return <div></div>;
  }
  return (
    <div
      onClick={() => setIsActive(!isActive)}
      style={{
        width: 60,
        height: 60,
        background: "#3038d2",
        borderRadius: "100%",
        cursor: "pointer",
        position: "fixed",
        right: 16,
        bottom: 60,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transform: "rotate(-5deg)",
      }}
    >
      {!isActive && (
        <RiChat2Fill
          style={{
            color: "#fff",
            alignSelf: "center",
            fontSize: 31,

            boxSizing: "content-box",
          }}
        ></RiChat2Fill>
      )}

      {isActive && (
        <IoCloseOutline
          style={{
            color: "#fff",
            alignSelf: "center",
            fontSize: 31,

            boxSizing: "content-box",
          }}
        ></IoCloseOutline>
      )}
    </div>
  );
}

export default FloatingButton;
