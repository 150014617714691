import React, { createContext, useState, useEffect, useRef } from "react";
const UploadFileContext = createContext();
const UploadFileContextProvider = ({ children }) => {
  const [uploadingFiles, setUploadingFiles] = useState([]);

  return (
    <UploadFileContext.Provider value={[uploadingFiles, setUploadingFiles]}>
      {children}
    </UploadFileContext.Provider>
  );
};
export { UploadFileContext, UploadFileContextProvider };
