import { useContext } from "react";
import { UploadFileContext } from "../contexts/WordContext";

const useUploadingFiles = () => {
  const context = useContext(UploadFileContext);

  if (!context) {
    throw new Error("useUploadingFiles must be used within an AuthProvider");
  }

  return context;
};

export default useUploadingFiles;
