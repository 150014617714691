import React, { useRef, useEffect, useState } from "react";
import useOnScreen from "../hooks/useOnScreen";

const SeenHandler = ({ msg, onSeen }) => {
  const divRef = useRef(null);

  const isVisible = useOnScreen(divRef);
  useEffect(() => {
    if (isVisible) {
      onSeen(msg.fingerprint);
    }
  }, [isVisible]);
  return <div ref={divRef}>{isVisible && ``}</div>;
};

export default SeenHandler;
