import { IoIosAttach } from "react-icons/io";

import React, { useRef, useState } from "react";
import configs from "../configs";
import useUploadingFiles from "../hooks/useWordGroups";
import { v4 as uuidv4 } from "uuid";

const { SOCKET_HOST, SOCKET_PORT, API_ENDPOINT } = configs;

const UploadFile = ({ session_id, messages_count }) => {
  const fileInputRef = useRef(null);
  const [uploadingFiles, setUploadingFiles] = useUploadingFiles();
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append("type", "file");
      formData.append("session_id", session_id);
      formData.append("file", selectedFile);

      // Make a POST request using Axios

      setUploadingFiles((old) => {
        return [
          ...old,
          {
            formData,
            type: "file",
            id: uuidv4(),
          },
        ];
      });

      // fetch(`${API_ENDPOINT}/upload`, {
      //   method: "POST",
      //   body: formData,
      // })
      //   .then((response) => response.json())
      //   .then((data) => {
      //     console.log("File uploaded successfully:", data);
      //   })
      //   .catch((error) => {
      //     console.error("Error uploading file:", error);
      //   });
    } else {
      console.warn("No file selected for upload");
      // Handle case where no file is selected
    }
  };

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        onChange={handleFileChange}
        ref={fileInputRef}
      />

      <IoIosAttach
        onClick={() => {
          if (messages_count == 0) {
            return;
          }
          fileInputRef.current.click();
        }}
        style={{
          color: "rgb(121 121 121)",
          fontSize: 29,
          // background: allowed_to_push_send ? "#053fd8" : "gray",
          padding: 9,
          borderRadius: "100%",
          marginRight: 9,
          cursor: "pointer",
          boxSizing: "content-box",
          
        }}
      ></IoIosAttach>
    </div>
  );
};

export default UploadFile;
